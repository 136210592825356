import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LukeImage from "../components/lukeImage";
import Carousel from "../components/carousel";
export const _frontmatter = {
  "title": "Press Start"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Hey! 👋 I'm Luke Willis`}</h1>
    <h2>{`I'm a full-stack developer who helps startups grow and improve retention through better UX and data-driven decision-making. More than a yes-man… I'm your technical business partner.`}</h2>
    <p>{`Whether you're in travel, health tech, web3, telecom, gaming, or even building materials, I've spent the last decade seeking to understand the differences and similarities between your industry and others. `}</p>
    <p>{`Everyone touts data-driven decision-making as a core competency, but few businesses know how and when to properly capture useful data. Whether qualitative or quantitative research is needed, I will help you understand your customers' behavior and develop web-based experiences that solve their most important problems.`}</p>
    <hr />
    <LukeImage mdxType="LukeImage" />
    <h3>{`About Luke`}</h3>
    <p>{`I've been building easy-to-use, accessible web apps with React since 2017. While I've worked in a lot of different industries and for companies of different sizes and maturity, I see the same kinds of problems frequently. I greatly enjoy interviewing users, gathering analytics, conducting surveys, testing hypotheses through rapid prototyping, and designing A/B tests.`}</p>
    <p>{`Frequently in the past, when brought on as a developer, I've been expected to accept direction from product and design. Developers are often left out of the customer loop. The business expects developers to build pixel perfect apps whether or not they understand why it matters.`}</p>
    <p>{`If that's what you want, I'm not your guy. I take personal responsibility for your customers' problems. That means I have to get close to the customer to understand their needs. Sometimes it even means saying no to product managers or designers who have put in a ton of work without engineering input. By involving me earlier in the process, we can move faster and save money on development costs all while making your customers happier than ever.`}</p>
    <hr />
    <h3>{`My Projects`}</h3>
    <Carousel mdxType="Carousel" />
    <hr />
    <h3>{`Questions?`}</h3>
    <p>{`If you need a quick answer to get your project unstuck, you can email me any time. If your company needs additional support, I occasionally take on new clients on a monthly retainer. Send me details about your biggest problem to get started.`}</p>
    <a className="cta" href="mailto:luke@lukewillis.com">Email me »</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      